<template>
    <div id="money_log">
        <div class="admin_main_block">
            <div class="user_temp_title">
                资金明细
            </div>
            <div class="admin_main_block_top clearfix">
                <div class="admin_main_block_left">
                    <div style="width:160px">
                        <el-select class="select_class" size="small" v-model="searchData.type" placeholder="类型" @change="search"  clearable>
                            <el-option value="1" label="收入"></el-option>
                            <el-option value="2" label="支出"></el-option>
                        </el-select>
                    </div>
                    <div style="width:160px">
                        <el-select class="select_class" size="small" v-model="searchData.dealType" placeholder="交易类型" @change="search" clearable>
                            <el-option value="1" label="充值"></el-option>
                            <el-option value="2" label="下单支出"></el-option>
                            <el-option value="3" label="推广获利"></el-option>
                            <el-option value="4" label="订单包裹退款"></el-option>
                            <el-option value="5" label="提现支出"></el-option>
                            <el-option value="6" label="提现失败"></el-option>
                            <el-option value="7" label="升级会员"></el-option>
                            <el-option value="100" label="其他"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            size="small"
                            clearable
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"></el-date-picker>
                    </div>
                    <div>
                        <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
                        <el-button size="small" @click="exportExcel" type="primary">导出表格</el-button>
                    </div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table size="small" :data="list" v-loading = 'loading'>
                    <el-table-column label="创建时间" prop="create_time">
                      
                    </el-table-column>
                    <el-table-column label="类型">
                        <template slot-scope="scope">
                            <span>{{scope.row.type==1?'收入':'支出'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="资金变动" prop="amount">
                        <template slot-scope="scope">
                            <span>{{scope.row.type==1?"+"+scope.row.amount:"-"+scope.row.amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" prop="dealDesc">
                        <template slot-scope="scope">
                            <span>{{scope.row.remark?'【'+scope.row.remark+'】':''}}{{scope.row.dealDesc}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="home_fy_block">
                    <el-pagination
                        v-if="total>0"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="searchData.pageNo"
                        :page-size="searchData.pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            time: [],
            searchData:{
                pageNo:1,
                pageSize:10,
                startTime:'',
                endTime:'',
                type:"",
                id:localStorage.getItem('ids')
            },
            list:[],
            total:0,
            loading:false,//防重复点击
        }
    },
    created(){
        this.getList();
    },
    mounted(){},
    watch: {},
    methods: {
        search(){
            this.searchData.pageNo = 1;
            this.getList();
        },
        getList(){
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/flowlist',
                params:this.searchData,
                success: res => {
                    this.list = res[0];
                    this.total = res[1];
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
         handleCurrentChange(val){
            this.searchData.pageNo = val;
            this.getList();
        },
        exportExcel(){
            let _this = this

            if(this.loading) return
            this.loading = true
            let postData = this.$qs.stringify(this.searchData);
            this.$axios.post("/bus/flow/exportList",postData,{
                responseType: 'blob',
            }).then(function(res) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(res.data);
                var filename = '资金明细-' + _this.$common.getDate(new Date().getTime()) + '.xls';
                a.href = url;
                a.download = filename;
                a.click();
                _this.loading = false
                window.URL.revokeObjectURL(url);
            }).catch(function(error) {
                //consloe.log(error);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
#money_log{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 855px;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .admin_main_block_top{
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #efefef;
            .admin_main_block_left{
                float: left;
                div{
                    float: left;
                    margin-right: 15px;
                }
            }
        }
    }
    .home_fy_block{
        margin-top: 30px;
        text-align: right;
        margin-right: 10px;
    }
}
</style>
